import React, { useState } from 'react';
import { TextField, Button, Grid, Box, Typography, FormControlLabel, Switch } from '@mui/material';
import { NetworkManager } from '../services/NetworkManager';

const RegisterForm = () => {
  const networkManager = new NetworkManager();
  const [formData, setFormData] = useState({
    fullname: '',
    username: '',
    email: '',
    isAdmin: false
  });
  const [emailError, setEmailError] = useState(''); // Állapot a hibaüzenethez

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Ellenőrizzük, hogy az email @slimservice.hu végződésű-e
    if (!formData.email.endsWith('@slimservice.hu')) {
      setEmailError('Csak @slimservice.hu e-mail címek engedélyezettek.');
      return;
    }

    setEmailError('');

    networkManager.post('/api/register/', formData).then((response) => {
        window.alert('Sikeres regisztráció!');
        window.location.reload();
    }).catch((error) => {
        console.error('Regisztrációs hiba:', error);
        window.alert('Hiba történt a regisztráció során.');
    });
  };

  return (
      <Box 
        sx={{
          padding: '40px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Új szervizes regisztrálása
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Teljes név"
                name="fullname"
                type="fullname"
                variant="outlined"
                value={formData.fullname}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Felhasználónév"
                name="username"
                variant="outlined"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                required
                error={!!emailError} // Megjeleníti a hibát, ha van
                helperText={emailError} // Hibaszöveg megjelenítése
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isAdmin}
                    onChange={handleChange}
                    name="isAdmin"
                    color="primary"
                  />
                }
                label="Adminisztrátor"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ padding: '10px' }}
              >
                Regisztrálás
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
  );
};

export default RegisterForm;