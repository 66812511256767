import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  TextField,
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  
} from "@mui/material";
import { NetworkManager } from "../services/NetworkManager";
import { Income, Worksheet } from "../models/WorkSheetModel";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { UserManager } from "../services/UserManager";
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
} from "chart.js";
import { Doughnut,Line } from "react-chartjs-2";
import axios from "axios";

ChartJS.register(
  ArcElement,
  Legend,

);

interface ServiceSheetFormProps {
  is_general?: boolean; // Opcionális boolean paraméter
}

interface DailyIncome {
  daileyCompanyIncome: number; // Napi bevétel
  addedDate: string; // Formázott dátum
}

const StatisticSheet: React.FC<ServiceSheetFormProps> = ({is_general=false}) => {
  const [serviceSheets, setServiceSheets] = useState<Worksheet[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const networkManager = useMemo(() => new NetworkManager(), []);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [selectedDateto, setSelectedDateto] = useState<Dayjs | null>(dayjs());
  const theme = useTheme();
  const [gridisOpened,setGridOpen]=useState(false);
  const [filteredSheets, setFilteredSheets] = useState<Worksheet[]>([]); // Szűrt adatok
  const [globalIncome, setGlobalIncome] = useState<number>(0);
  const[totalIncomeM,setTotalIncomeM]=useState(0);
  const[totalIncomeD,setTotalIncomeD]=useState(0);
  const[totalIncomeA,setTotalIncomeA]=useState(0);
  const[cleanings,setcleanings]=useState([]);
  const[otherExpenses,setOtherexpneses]= useState<Worksheet[]>([]);
  const[allpartsPriceByDate,setAllpartsPriceByDate]=useState(0);
  const [companyIncomeEachDay,setcompanyIncomeEachDay]= useState<DailyIncome[]>([]);
  const [formattedData, setFormattedData] = useState<{ date: string; total: number }[]>([]);
  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
  };
  const handleDateChange2 = (newDate: Dayjs | null) => {
    setSelectedDateto(newDate);
  };


  function decodeBase64(base64String: string): string {
    try {
      // Base64 dekódolás és UTF-8 visszaalakítás
      const decodedString = decodeURIComponent(
        atob(base64String)
          .split('')
          .map((char) => `%${('00' + char.charCodeAt(0).toString(16)).slice(-2)}`)
          .join('')
      );
      return decodedString;
    } catch (e) {
      console.error('Hiba a Base64 dekódolásban:', e);
      return base64String; // Ha hiba történik, az eredeti stringet adja vissza
    }
  }

  const getWorksheets = useCallback(async () => {
    setIsLoading(true);
    
    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    const date2 = selectedDateto ? selectedDateto.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
 
    const response = await networkManager.get('/api/worksheets/?date=' + date+ "&endDate="+ date2);
    const response2 = await axios.get('https://leltar.slimstore.hu/api/cardQR/?used=true&startDate='+date+'&endDate='+date2);
    const parts=  await networkManager.get('/api/parts/');

    console.log("cumimuki",parts)


    const startDate = new Date(date);
    const endDate = new Date(date2);
    
//szűrés dűtum szerint
    const filteredPartsByPurchaseDate = parts.data.filter((item: { purchase_date: string | number | Date; }) => {
      const purchaseDate = item.purchase_date ? new Date(item.purchase_date) : null;

      return (
        purchaseDate &&
        purchaseDate >= startDate &&
        purchaseDate <= endDate
      );
    });
    const VAT_RATE = 0.27; // Ez 27% ÁFA-t jelent
    const totalNetPrice = filteredPartsByPurchaseDate.reduce((total: number, item: { price: string; }) => {
      // Ha az elemben van érvényes ár, számítsd a nettó értéket
      if (item.price && !isNaN(parseFloat(item.price))) {
        const netPrice = parseFloat(item.price) / (1 + VAT_RATE); // Nettósítás
        return total + netPrice; // Nettó árak összege
      }
      return total; // Ha nincs érvényes ár, hagyd ki
    }, 0);

    setAllpartsPriceByDate(Math.round(totalNetPrice));
    

    const newServiceSheets = response.data.worksheets;

    
    const decodedCleanings = response2.data.map((item: any) => {
      return {
        ...item,
        qrstring: decodeBase64(item.qrstring), // Base64 dekódolás
      };
    });

    // Az új elemeket hozzáadjuk a meglévőkhöz
    setServiceSheets(newServiceSheets);
    setOtherexpneses(newServiceSheets.filter((item: { is_general: boolean; })=> item.is_general===true))

    setcleanings(decodedCleanings)
 
    setIsLoading(false);
  }, [networkManager, selectedDate,selectedDateto]);

  useEffect(() => {
    getWorksheets();
  },[getWorksheets]);


 function ownIncomeByRow(userid: number, bevetel: number, kiadas: number,incomes : Income[],paymentmethod:string): string  {
    const loggeduserId = parseInt(UserManager.getInstance().getUserId()); // Local storage id konvertálása számra

    let hasDiscount = incomes.some((row) => {
      const amount = parseInt(row.amount);
      const newPrice = row.new_price ? parseInt(row.new_price) : amount; // Ha new_price undefined, az amount értékét használja
      return amount !== newPrice;
    });
    
    const techinicianComission = incomes.reduce((sum, row) => {
      const nettIncome = Math.round(((parseInt(row.amount)) / 1.27));

      return sum + (nettIncome > 0 ? nettIncome : 0); // Csak pozitív nettó nyereséget számolunk
    }, 0);
    if(loggeduserId===26){
      if(paymentmethod!=='warranty'){
      if(hasDiscount){
        return Math.round(((bevetel - kiadas)/1.27)-(techinicianComission-kiadas/1.27) * 0.1).toLocaleString() + " Ft"; 
      } else{
        return Math.round(((bevetel - kiadas)/1.27) * 0.90).toLocaleString() + " Ft"; 
      }
    }else{
      return Math.round((bevetel - kiadas)/1.27).toLocaleString()+"Ft";
    }
    } else{

      if ((userid === 23)&&(loggeduserId===23)) { // Ellenőrzés, hogy csak a megfelelő ID esetén fusson le
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.1).toLocaleString() + " Ft"; // 10%-os saját nyereség
        } else {
          return "n.a."; // Ha negatív a nyereség
        }
      }else if((userid !== 23)&&(loggeduserId===23)){
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.05).toLocaleString() + " Ft"; // 5%-os saját nyereség
        } else {
          return "n.a."; // Ha negatív a nyereség
        }
      }else if(loggeduserId===userid){
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.05).toLocaleString() + " Ft"; // 5%-os saját nyereség
        } else {
          return "n.a."; // Ha negatív a nyereség
        }
          
      }else{
        return "n.a."; 
      }
   
    }
    }

  useEffect(() => {
   if(is_general){
     setFilteredSheets(serviceSheets.filter(sheet => sheet.is_general===true));
   } else {
     setFilteredSheets(serviceSheets.filter(sheet => sheet.is_general===false && sheet));
   }
  }, [selectedDate, selectedDateto, serviceSheets, is_general]);

  function totalIncomeCalculate(filteredSheets: Worksheet[]): string {
    const totalIncome = filteredSheets.reduce((sum, row) => {
      const nettIncome = Math.round((row.total_incomes - row.total_expenses) / 1.27);
      return sum + (nettIncome > 0 ? nettIncome : 0); // Csak pozitív nettó nyereséget számolunk
    }, 0);
    return totalIncome.toLocaleString();
  }

  function companyProfit(filteredSheets: Worksheet[]): number {
    const totalIncome = filteredSheets.reduce((sum, row) => {
      const nettIncome = Math.round(((row.total_incomes - row.total_expenses) / 1.27));
      return sum + (nettIncome > 0 ? nettIncome : 0); // Csak pozitív nettó nyereséget számolunk
    }, 0);


    return totalIncome;
  }
  //A kiziettt jutalékok oszlophoz kell

  function calculatePaidCommission(userid: number,username:string, bevetel: number, kiadas: number,incomes : Income[]) {
   
    const techinicianComission = incomes.reduce((sum, row) => {
      const nettIncome = Math.round(((parseInt(row.amount)) / 1.27));
      return sum + (nettIncome > 0 ? nettIncome : 0); // Csak pozitív nettó nyereséget számolunk
    }, 0);
    if((techinicianComission-kiadas/1.27)>0){
        if (userid===23) {
          return username +"("+Math.round((techinicianComission-kiadas/1.27) * 0.1).toLocaleString() + " Ft)"; // 10%-os saját nyereség
        } else {
          const formattedCommission = Math.round((techinicianComission - kiadas / 1.27) * 0.05).toLocaleString();
          return `${username} (${formattedCommission} Ft) / Máté (${formattedCommission} Ft)`;  // 5%-os saját nyereség
        }
      }else{
         return "nincs jutalék"
      }
      }
  function totalPaidCommission(filteredSheets: Worksheet[]): number {
    // Külső reduce a filteredSheets elemein
    const totalCommission = filteredSheets.reduce((sum, row) => {
      if (!row.incomes || row.incomes.length === 0) {
        return sum; // Ha nincs incomes, lépj tovább
      }
  
      // Belső reduce az incomes tömb feldolgozására
      const partCommission = row.incomes.reduce((partSum, partRow) => {
        // Nettó érték számítása
        const nettIncome = Math.round(
          ((parseInt(partRow.amount) - row.total_expenses) / 1.27) * 0.10
        );
  
        // Csak pozitív nettó értéket adj hozzá
        return partSum + (nettIncome > 0 ? nettIncome : 0);
      }, 0);
  
      // Az összeghez hozzáadjuk az aktuális row értékét
      return sum + partCommission;
    }, 0);
  


    // Formázott összeg visszatérítése
    return totalCommission + getCleaningProfitforUserId(0);
  }

  const sumotherExpenses = () => {
    const totalPrice = otherExpenses.reduce((partSum: number, partRow: { expenses: { price: string }[] }) => {
      // Ellenőrizzük, hogy az expenses tömb nem üres-e
      if (partRow.expenses && partRow.expenses.length > 0) {
        const itemSum = partRow.expenses.reduce((expenseSum: number, expense: { price: string }) => {
          // Nettó érték számítása
          const itemPrice = Math.round(parseFloat(expense.price) / 1.27);
          return expenseSum + itemPrice; // Összeadjuk az egyes tételeket
        }, 0);
        return partSum + itemSum; // Hozzáadjuk az összeghez
      }
      return partSum; // Ha nincs expenses, lépj tovább
    }, 0);
  
    return totalPrice;
  };

  useEffect(() => {
    let totalIncome = 0;
  
    const dailyIncomeData: DailyIncome[] = []; // Explicit típusdefiníció
    filteredSheets.forEach((sheet) => {
      const dailyIncome = calculateIncome(
        sheet.user,
        sheet.total_incomes,
        sheet.total_expenses,
        sheet.incomes,
        sheet.payment_method
      ); // Napi bevétel számítása
  
      totalIncome += dailyIncome; // Összesített bevétel frissítése
  
      const dateObj = new Date(sheet.date_created);
      const formattedDate = dateObj.toISOString().slice(0, 19).replace("T", " ");
  
      dailyIncomeData.push({
        daileyCompanyIncome: dailyIncome,
        addedDate: formattedDate,
      });
    });
  
    // Állapot frissítése az egyes napok adataival
    setcompanyIncomeEachDay(dailyIncomeData);
  
    // Összesített bevétel állapot frissítése
    setGlobalIncome(totalIncome);
  }, [filteredSheets]);


  useEffect(() => {
    const dailyAggregatedData: { [key: string]: number } = {};
  
    companyIncomeEachDay.forEach(({ daileyCompanyIncome, addedDate }) => {
      const dateKey = addedDate.split(" ")[0]; // Csak a dátum részt vesszük
      if (!dailyAggregatedData[dateKey]) {
        dailyAggregatedData[dateKey] = 0;
      }
      dailyAggregatedData[dateKey] += daileyCompanyIncome; // Napi összeg hozzáadása
    });
  
    const formattedData = Object.entries(dailyAggregatedData).map(([date, total]) => ({
      date,
      total,
    }));
  
    setFormattedData(formattedData); // Állapot frissítése a grafikonhoz
  }, [companyIncomeEachDay]);

  const calculateIncome = (userid: number, bevetel: number, kiadas: number, incomes: Income[],paymentmethod:string) => {
    const loggeduserId = parseInt(UserManager.getInstance().getUserId());

   
    let hasDiscount = incomes.some((row) => {
      const amount = parseInt(row.amount);
      const newPrice = row.new_price ? parseInt(row.new_price) : amount; // Ha new_price undefined, az amount értékét használja
      return amount !== newPrice;
    });
    
   
    const techinicianComission = incomes.reduce((sum, row) => {
      const nettIncome = Math.round(parseInt(row.amount) / 1.27);
      return sum + (nettIncome > 0 ? nettIncome : 0);
    }, 0);

    if(loggeduserId===26){
      if(paymentmethod!=="warranty"){
      if(hasDiscount){
        return Math.round(((bevetel - kiadas)/1.27)-(techinicianComission-kiadas/1.27) * 0.1)
      } else{
        return Math.round(((bevetel - kiadas)/1.27) * 0.90)
      }
    }else{
      return Math.round((bevetel - kiadas)/1.27)
    }
    } else{

      if ((userid === 23)&&(loggeduserId===23)) { // Ellenőrzés, hogy csak a megfelelő ID esetén fusson le
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.1)
        } else {
          return 0;
        }
      }else if((userid !== 23)&&(loggeduserId===23)){
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.05)
        } else {
          return 0;
        }
      }else if(loggeduserId===userid){
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.05)
        } else {
          return 0
        }
          
      }else{
        return 0
      }
  };
  };
  const getCleaningProfitforUserId = useCallback((userId: number): number => {
    let totalCleaningProfit = 0;
  
    cleanings.forEach(sheet => {
      const cardData = JSON.parse(sheet["qrstring"]);
      if (cardData["cardType"] === "Tisztítás kártya") {
        const price = parseInt(cardData["price"]);
        const nettIncome = price / 1.27;
        if (userId === 0) {
          totalCleaningProfit += Math.round(nettIncome * 0.1);
        }
        if (sheet["serviceEmployeid"] === userId) {
          if (userId === 23) {
            totalCleaningProfit += Math.round(nettIncome * 0.1);
          } else {
            totalCleaningProfit += Math.round(nettIncome * 0.05);
          }
        } else if (sheet["serviceEmployeid"] !== 23) {
          if (userId === 23) {
            totalCleaningProfit += Math.round(nettIncome * 0.05);
          }
        }
      }
    });
  
    return totalCleaningProfit;
  }, [cleanings]);
  useEffect(() => {
    let totalIncomeAttila = 0;
    let totalIncomeMáté = 0;
    let totalIncomeDániel = 0;
  
    filteredSheets.forEach(sheet => {
      if (sheet.user_name === "Attila") {
        // Attila saját jutaléka
        totalIncomeAttila += paidCommissionForTechnician(sheet.user_name, sheet.total_incomes, sheet.total_expenses, sheet.incomes);
        // Máté 5%-os jutaléka Attila szervizei után
        totalIncomeMáté += paidCommissionForTechnician("Máté", sheet.total_incomes, sheet.total_expenses, sheet.incomes, true);
      } else if (sheet.user_name === "Máté") {
        // Máté saját 10%-os jutaléka
        totalIncomeMáté += paidCommissionForTechnician(sheet.user_name, sheet.total_incomes, sheet.total_expenses, sheet.incomes);
      } else if (sheet.user_name === "Dániel") {
        // Dániel saját jutaléka
        totalIncomeDániel += paidCommissionForTechnician(sheet.user_name, sheet.total_incomes, sheet.total_expenses, sheet.incomes);
        // Máté 5%-os jutaléka Dániel szervizei után
        totalIncomeMáté += paidCommissionForTechnician("Máté", sheet.total_incomes, sheet.total_expenses, sheet.incomes, true);
      }
    });
    totalIncomeMáté += getCleaningProfitforUserId(23);
    totalIncomeAttila += getCleaningProfitforUserId(24);
    totalIncomeDániel += getCleaningProfitforUserId(27);

    setTotalIncomeD(totalIncomeDániel);
    setTotalIncomeA(totalIncomeAttila);
    setTotalIncomeM(totalIncomeMáté);
  }, [filteredSheets, getCleaningProfitforUserId]);
  
  function paidCommissionForTechnician(user: string, bevetel: number, kiadas: number, incomes: Income[], isExtra = false): number {
    
    const partCommission = incomes.reduce((partSum, partRow) => {
      // Nettó érték számítása
      const nettIncome = Math.round(
        ((parseInt(partRow.amount) - kiadas) / 1.27) 
      );
      
      // Csak pozitív nettó értéket adj hozzá
      return partSum + (nettIncome > 0 ? nettIncome : 0);
    }, 0);
  

    if (partCommission > 0) {
      if (user === "Attila") {
        return Math.round(partCommission * 0.05); // 5%-os saját nyereség
      } else if (user === "Máté") {
        return isExtra
          ? Math.round(partCommission * 0.05) // Extra 5%-os nyereség mások szervizei után
          : Math.round(partCommission * 0.1); // Saját 10%-os nyereség
      } else if (user === "Dániel") {
        return Math.round(partCommission * 0.05); // 5%-os saját nyereség
      }
    }
  
    return 0; // Default érték, ha nincs nyereség vagy user nem egyezik
  }
  
  const doughnutData = {
    labels: ["Máté", "Attila", "Dániel"], // A három technikus neve
    datasets: [
      {
        label: "Kiosztott jutalékok",
        data: [totalIncomeM, totalIncomeA, totalIncomeD], // A három technikus jutaléka
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], // Színek
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], // Színek hover állapotban
      },
    ],
  };

  const doughnutDataTotalIncome = {
    labels: ["Cégnyereség", "Kifizett jútalékok"], // A három technikus neve
    datasets: [
      {
        label: "Teljes nyereség elosztás",
        data: [globalIncome,totalPaidCommission(filteredSheets)], // A három technikus jutaléka
        backgroundColor: ["#FF6384", "#36A2EB"], // Színek
        hoverBackgroundColor: ["#FF6384", "#36A2EB"], // Színek hover állapotban
      },
    ],
  };

  const cleanandserviceratio = {
    labels: ["szervíz", "tisztítás"], 
    datasets: [
      {
        label: "szervíz-tisztítás eloszlás",
        data: [filteredSheets.length,cleanings.length], // A három technikus jutaléka
        backgroundColor: ["#FF6384", "#36A2EB"], // Színek
        hoverBackgroundColor: ["#FF6384", "#36A2EB"], // Színek hover állapotban
      },
    ],
  };

  const chartData = useMemo(() => {
    return {
      labels: formattedData.map((item) => item.date),
      datasets: [
        {
          label: "Napi összeg (Ft)",
          data: formattedData.map((item) => item.total),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };
  }, [formattedData]);

  return (
    isLoading ? (
      <div>Töltés...</div>
    ) : (
    <div>
        <div style={{display:"flex", justifyContent:"center"}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Válassz dátumot"
        value={selectedDate}
        onChange={handleDateChange}
        slots={{
          textField: textFieldProps => <TextField {...textFieldProps} />
        }}
      />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Válassz dátumot"
        value={selectedDateto}
        onChange={handleDateChange2}
        slots={{
          textField: textFieldProps => <TextField {...textFieldProps} />
        }}
      />
      </LocalizationProvider>

        </div>

        <Box
  sx={{
    display: "flex",
    justifyContent: "center", // Horizontális középre igazítás
    alignItems: "center",    // Vertikális középre igazítás
    marginTop: "20px",
    height: "5vh",         // A teljes nézetmagasság
  }}
>
  <IconButton onClick={() => {setGridOpen((prev) => !prev);}}>
 {gridisOpened ? <ArrowDownwardIcon></ArrowDownwardIcon> : <ArrowUpwardIcon></ArrowUpwardIcon>}
  </IconButton>
</Box>
      <br></br>
      <br></br>
    
      
{gridisOpened &&

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table key={"table"}>
          <TableHead key={"TablehxRow"}>
            <TableRow key={"Tablehead"}>
              <TableCell>ID</TableCell>
              <TableCell>Felhasználó</TableCell>
              <TableCell>Dátum</TableCell>
              <TableCell>Készpénz / Bankkártya</TableCell>
              <TableCell>Kiadások</TableCell>
              <TableCell>Bevételek</TableCell>
              <TableCell>Helyszín</TableCell>
              <TableCell sx={{ textAlign:"center", alignContent:"center" }}>Nettó nyereség</TableCell>
              <TableCell sx={{ textAlign:"center" }}>  {parseInt(UserManager.getInstance().getUserId()) === 26 ? "Cégnyereség" : "Saját nyereség"}</TableCell>
            {parseInt(UserManager.getInstance().getUserId()) === 26 && (   <TableCell sx={{ textAlign: "center" }}>Kiosztott jutalék</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody> {}
            {filteredSheets.map((sheet) => (
              <TableRow key={sheet.id}>
                <TableCell>{sheet.id}</TableCell>
                <TableCell>{sheet.user}</TableCell>
                <TableCell>{sheet.id}</TableCell>
                <TableCell>{sheet.payment_method}</TableCell>
                <TableCell>
  {sheet.expenses && sheet.expenses.length > 0 && (
    sheet.expenses.map((expense, i) => (
      <div key={i}>
        <Typography variant="subtitle2" color="textPrimary" component="span">
          {expense.description} - {parseInt(expense.price).toLocaleString() + " Ft"}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="span" style={{ marginLeft: 4 }}>
          {"(nettó: " + Math.round(parseInt(expense.price) / 1.27).toLocaleString() + " Ft)"}
        </Typography>
      </div>
    ))
  )}
</TableCell>
                <TableCell>
                  {sheet.incomes && sheet.incomes.map((income, i) => (
                    <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px 12px",
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRadius: "8px",
                      margin: "8px 0",
                      backgroundColor: theme.palette.background.paper,
                      transition: "all 0.3s",
                      "&:hover": {
                        boxShadow: `0px 4px 12px ${theme.palette.grey[300]}`,
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    {/* Bal oldali, eredeti ár */}
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                      <Typography variant="subtitle2" color="textPrimary">
  {income.description} - {parseInt(income.amount).toLocaleString()} Ft
  <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 4 }}>
    {"(nettó: " + Math.round(parseInt(income.amount) / 1.27).toLocaleString() + " Ft)"}
  </Typography>
</Typography>
                      </Grid>
          
                      {/* Középső, kártya információ */}
                      <Grid item xs={3}>
                        <Typography variant="body2" color="textSecondary">
                          {income.card === "" ? "Kártya nélkül" : income.card}
                        </Typography>
                      </Grid>
          
                      {/* Jobb oldali, új ár */}
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" color="primary" fontWeight="600">
                          {income.new_price ? parseInt(income.new_price).toLocaleString() + " Ft" : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  ))}
                </TableCell>
                <TableCell>
                    <div key={sheet.location}>
                      {sheet.location}
                    </div>
                </TableCell>
                <TableCell align="center">
  <div key={sheet.location}>
    {(sheet.total_incomes - sheet.total_expenses > 0
      ? Math.round((sheet.total_incomes - sheet.total_expenses) / 1.27).toLocaleString() + " Ft"
      : "n.a.")}
  </div>
</TableCell>
<TableCell align="center">

{ownIncomeByRow(sheet.user,sheet.total_incomes,sheet.total_expenses,sheet.incomes,sheet.payment_method)}
</TableCell>


<TableCell align="center">

{calculatePaidCommission(sheet.user,sheet.user_name,sheet.total_incomes,sheet.total_expenses,sheet.incomes)}
</TableCell> 

              </TableRow>
            ))}

        

<TableRow sx={{ border: "2px solid black", marginTop: "20px" }}>
          <TableCell colSpan={10} sx={{ textAlign: "right", paddingRight: "16px", border: "2px solid black" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px" }}>
              <Typography variant="body1">
              Nettó nyereség összesen: {companyProfit(filteredSheets).toLocaleString()} Ft
              </Typography>
              <Typography variant="body1">
                {parseInt(UserManager.getInstance().getUserId()) === 26 ? "Cégnyereség csak a szervízből" : "Saját nyereség a szervízből"} {globalIncome} Ft
              </Typography>
              <Typography variant="body1">
                Cégnyereség(nettó nyereség-teljes kifizetett jutalékok) {companyProfit(filteredSheets)-totalPaidCommission(filteredSheets)} Ft
              </Typography>
              {parseInt(UserManager.getInstance().getUserId()) === 26 && (
                <Typography variant="body1">
                  Kiosztott jutalék összesen: {totalPaidCommission(filteredSheets).toLocaleString() + " Ft (Ebből tisztítás: "+getCleaningProfitforUserId(0).toLocaleString() + " Ft)"}
                </Typography>
              )}
            </Box>
          </TableCell>
        </TableRow>



          </TableBody>
        </Table>
      </TableContainer>

    }
      <Box sx={{ padding: "16px" }}>
  <Typography variant="h5" gutterBottom>
    Statisztika
  </Typography>

  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    {/* Egy sor */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between", // A címke és az érték elválasztása
        alignItems: "center", // Középre igazítás függőlegesen
        borderBottom: "1px solid #ddd", // Elválasztó vonal
        padding: "8px 0",
      }}
    >
      {/* Bal oldali címke */}
      <Typography variant="subtitle1" fontWeight="bold">
        Nettó nyereség összesen: 
      </Typography>

      {/* Jobb oldali érték */}
      <Typography variant="body1">{totalIncomeCalculate(filteredSheets)} Ft</Typography>
    </Box>

    

    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}
    >
      {/* Bal oldali címke */}
      <Typography variant="subtitle1" fontWeight="bold">
      {parseInt(UserManager.getInstance().getUserId()) === 26 ? "Cégnyereség csak a szevízből:" : "Saját nyereség a szervízből:"} 
      </Typography>

      {/* Jobb oldali érték */}
      <Typography variant="body1">{globalIncome} Ft </Typography>
    </Box>


    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}
    >
      {/* Bal oldali címke */}
      <Typography variant="subtitle1" fontWeight="bold"   sx={{
    textDecoration: "underline", // Aláhúzás
  }}>
   
      
                Cégnyereség(nettó nyereség-teljes kifizetett jutalékok)
            
      </Typography>

      {/* Jobb oldali érték */}
      <Typography variant="body1">{companyProfit(filteredSheets)-totalPaidCommission(filteredSheets)} Ft </Typography>
    </Box>

    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}
    >
      {/* Bal oldali címke */}
      <Typography variant="subtitle1" fontWeight="bold"   sx={{
    textDecoration: "underline", // Aláhúzás
  }}>
   
      
                Cégnyereség(nettó nyereség-teljes kifizetett jutalékok-raktárkészlet)
            
      </Typography>

      {/* Jobb oldali érték */}
      <Typography variant="body1">{companyProfit(filteredSheets)-totalPaidCommission(filteredSheets)-allpartsPriceByDate} Ft </Typography>
    </Box>


    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}
    >
      {/* Bal oldali címke */}
      <Typography variant="subtitle1" fontWeight="bold"   sx={{
    textDecoration: "underline", // Aláhúzás
  }}>
   
      
               Egyéb kiadások összesen:
            
      </Typography>

      {/* Jobb oldali érték */}
      <Typography variant="body1">  {sumotherExpenses().toLocaleString()} Ft </Typography>
    </Box>

    {/* Egy újabb sor */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}
    >
      {/* Bal oldali címke */}
      <Typography variant="subtitle1" fontWeight="bold">
      Kiosztott jutalék összesen:
      </Typography>

      {/* Jobb oldali érték */}
      <Typography variant="body1">{totalPaidCommission(filteredSheets)} </Typography>
    </Box>
   


<Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}

    >
      <Typography variant="subtitle1" fontWeight="bold">
        Kiosztott jutalék Máté összesen: 
      </Typography>
      <Typography variant="body1">{(totalIncomeM).toLocaleString()} Ft (Ebből tisztítás: {getCleaningProfitforUserId(23).toLocaleString()} Ft)</Typography>
    </Box>


    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}

    >
      <Typography variant="subtitle1" fontWeight="bold">
        Kiosztott jutalék Attila összesen: 
      </Typography>
      <Typography variant="body1">{(totalIncomeA).toLocaleString()} Ft (Ebből tisztítás: {getCleaningProfitforUserId(24).toLocaleString()} Ft)</Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        padding: "8px 0",
      }}

    >
      <Typography variant="subtitle1" fontWeight="bold">
        Kiosztott jutalék Dani összesen: 
      </Typography>
      <Typography variant="body1">{(totalIncomeD).toLocaleString()} Ft (Ebből tisztítás: {getCleaningProfitforUserId(27).toLocaleString()} Ft)</Typography>
    </Box>

  </Box>

<br></br>
<br></br>
<Box>
<Box
  sx={{
    display: "flex",
    flexDirection: "column", // A gyerekek egymás alá kerülnek
    alignItems: "center", // Középre igazítás
    width: "400px", // Konténer szélessége
    margin: "auto", // Középre igazítás
  }}
>
  <Box 
    sx={{
      display: "flex",
      flexDirection: "column", // A gyerekek egymás alá kerülnek
      alignItems: "center", // Középre igazítás
      width: "400px", // Konténer szélessége
      margin: "auto", // Középre igazítás
    }}>
<Doughnut
    data={doughnutData}
    options={{
      responsive: true,
      maintainAspectRatio: true,
     
    }}
  />
  <Typography
    variant="body1"
    sx={{
      marginTop: "16px", // Távolság a grafikon és a szöveg között
      textAlign: "center",
    }}
  >
    Kiosztott jutalékok megoszlása: Máté, Attila és Dániel között.
  </Typography>
  </Box>
  
  <Box 
    sx={{
      display: "flex",
      flexDirection: "column", // A gyerekek egymás alá kerülnek
      alignItems: "center", // Középre igazítás
      width: "400px", // Konténer szélessége
      margin: "auto", // Középre igazítás
    }}>
  <Doughnut
    data={doughnutDataTotalIncome}
    options={{
      responsive: true,
      maintainAspectRatio: true,
     
    }}
  />
  <Typography
    variant="body1"
    sx={{
      marginTop: "16px", // Távolság a grafikon és a szöveg között
      textAlign: "center",
    }}
  >
   A teljes nyereség
  </Typography>
  </Box>


  <Box 
    sx={{
      display: "flex",
      flexDirection: "column", // A gyerekek egymás alá kerülnek
      alignItems: "center", // Középre igazítás
      width: "400px", // Konténer szélessége
      margin: "auto", // Középre igazítás
    }}>
  <Doughnut
    data={cleanandserviceratio}
    options={{
      responsive: true,
      maintainAspectRatio: true,
     
    }}
  />
  <Typography
    variant="body1"
    sx={{
      marginTop: "16px", // Távolság a grafikon és a szöveg között
      textAlign: "center",
    }}
  >
  Tisztítás/szervíz aránya erre az időszkra
  </Typography>
  </Box>

</Box>
<br></br>
<Box
 sx={{
  display: "flex",
  flexDirection: "column", // A gyerekek egymás alá kerülnek
  alignItems: "center", // Középre igazítás
  width: "800px", // Konténer szélessége
  margin: "auto", // Középre igazítás
}}>
  <Line data={chartData}></Line>
  <Typography
    variant="body1"
    sx={{
      marginTop: "16px", // Távolság a grafikon és a szöveg között
      textAlign: "center",
    }}
  >
   Napi nyereségek
  </Typography>
</Box>
</Box>
 
  
</Box>
Í


    </div>
  ));
};



export default StatisticSheet;