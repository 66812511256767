import React, { useState } from 'react';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';
import { NetworkManager } from '../services/NetworkManager';

const PasswordChangeForm = () => {
  const networkManager = new NetworkManager();
  const [formData, setFormData] = useState({
    current_password: '',
    new_password: ''
  });
  const [passwordError, setPasswordError] = useState(''); // Állapot a hibaüzenethez

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Ellenőrizzük, hogy a jelszók mezők ki vannak-e töltve
    if (!formData.current_password || !formData.new_password) {
      setPasswordError('Mindkét jelszó mező kitöltése kötelező.');
      return;
    }

    // Töröljük a hibát, ha a mezők helyesen vannak kitöltve
    setPasswordError('');

    // Jelszóváltoztatás POST kérés
    networkManager.post('/api/password_change/', formData)
      .then((response) => {
        window.alert('Jelszó sikeresen megváltoztatva!');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Hiba a jelszóváltoztatás során:', error);
        setPasswordError('Hiba történt a jelszóváltoztatás során. Kérjük, ellenőrizze a megadott adatokat.');
      });
  };

  return (
    <Box 
      sx={{
        padding: '40px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Jelszóváltoztatás
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Jelenlegi jelszó"
              name="current_password"
              type="password"
              variant="outlined"
              value={formData.current_password}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Új jelszó"
              name="new_password"
              type="password"
              variant="outlined"
              value={formData.new_password}
              onChange={handleChange}
              required
              error={!!passwordError} // Megjeleníti a hibát, ha van
              helperText={passwordError} // Hibaszöveg megjelenítése
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ padding: '10px' }}
            >
              Jelszó módosítása
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PasswordChangeForm;