import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Box,
  CssBaseline,
  ListItemIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BuildIcon from '@mui/icons-material/Build';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PieChartSharpIcon from '@mui/icons-material/PieChartSharp';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Storefront } from '@mui/icons-material';
import { PasswordRounded } from '@mui/icons-material';
import Person from '@mui/icons-material/Person';
import PartTypesList from './PartTypes';
import { UserManager } from '../services/UserManager';
import PhoneTypesList from './PhoneTypes';
import LocationsList from './Locations';
import PartsList from './InventoryManager';
import ServiceSheetForm from './WorkSheet';
import Dashboards from './Dashboards';
import RegisterForm from './RegisterForm';
import PasswordChangeForm from './PasswordChangeForm';
import StatisticSheet from "./StatisticSheet"
import CleanCard from "./CleanCard"

const drawerWidth = 250;
const appBarHeight = 64; // Az AppBar tipikus magassága pixelben

// Például ezek lehetnek különálló komponensek, amelyeket be szeretnél illeszteni
const Servizek: React.FC<{ is_general: boolean }> = ({ is_general }) => (
  <ServiceSheetForm is_general={is_general} />
);
const Raktarkeszlet = () => <PartsList />;
const Telefontipusok = () => <PhoneTypesList />
const Alkatresztipusok = () => <PartTypesList />;
const Locations = () => <LocationsList />;
const Registration = () => <RegisterForm />;
const PasswordChange = () => <PasswordChangeForm />

export const Home = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState('dashboard');
  const userManager = UserManager.getInstance();
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    userManager.logout();
  };

  // Tartalom dinamikus megjelenítése
  const renderContent = () => {
    switch (selectedContent) {
      case 'szervizek':
          return <Servizek is_general={false} />; 
      case 'kiadások':
          return <Servizek is_general={true} />;
      case 'statisztikák':
          return <StatisticSheet is_general={false}/>;
      case 'tisztitas':
            return <CleanCard />;
      case 'raktarkeszlet':
        return <Raktarkeszlet />;
      case 'telefontipusok':
        return <Telefontipusok />;
      case 'helyszinek':
          return <Locations />;
      case 'regisztráció':
          return <Registration />;
      case 'alkatresztipusok':
        return <Alkatresztipusok />;
      case 'jelszó':
        return <PasswordChange />;
      default:
        return <Dashboards />;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />

      {/* AppBar rögzítve a képernyő tetejére */}
      <AppBar position="fixed" sx={{ backgroundColor: '#FFB80A', color: 'black', zIndex: (theme) => theme.zIndex.drawer }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'black', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => setSelectedContent('')}>
            Slim Service - Leltár 
            <Typography variant="caption" component="span" sx={{textDecoration:1,marginLeft:2, fontSize:14, padding: "5px", borderRadius: 10, border: "1px solid black"}}>
            {localStorage.getItem('username')}
        </Typography>
          </Typography>
     
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (jobb oldali menü) */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ width: drawerWidth }}
      >
        <Box
          sx={{ width: drawerWidth }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItemButton onClick={() => setSelectedContent('szervizek')}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Szervizek" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('kiadások')}>
              <ListItemIcon>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <ListItemText primary="Kiadások" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('statisztikák')}>
              <ListItemIcon>
                <StackedLineChartIcon />
              </ListItemIcon>
              <ListItemText primary="Statisztikák" />
            </ListItemButton>

            
            <ListItemButton onClick={() => setSelectedContent('tisztitas')}>
              <ListItemIcon>
                <CleaningServicesIcon />
              </ListItemIcon>
              <ListItemText primary="Tisztítás" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('raktarkeszlet')}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Raktárkészlet" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('telefontipusok')}>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="Telefontípusok" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('alkatresztipusok')}>
              <ListItemIcon>
                <PieChartSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Alkatrésztípusok" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('helyszinek')}>
              <ListItemIcon>
                <Storefront />
              </ListItemIcon>
              <ListItemText primary="Helyszínek" />
            </ListItemButton>

            {userManager.checkIsSuperUser() && (
            <ListItemButton onClick={() => setSelectedContent('regisztráció')}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Regisztráció" />
            </ListItemButton>)}

            <ListItemButton onClick={() => setSelectedContent('jelszó')}>
              <ListItemIcon>
                <PasswordRounded />
              </ListItemIcon>
              <ListItemText primary="Jelszó megváltoztatása" />
            </ListItemButton>

            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Kijelentkezés" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>

      {/* Tartalom, amely az AppBar alatt kezdődik */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: `${appBarHeight}px` }}>
        {renderContent()} {/* A tartalom dinamikusan jelenik meg */}
      </Box>
    </Box>
  );
};

export default Home;