import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Scanner } from '@yudiel/react-qr-scanner';
import { UserManager } from '../services/UserManager';


const QR: React.FC = () => {
 const [qrString, setQrString] = useState<string>('');
 const [qrShow, setQrShow] = useState<boolean>(true);
 const [isValid, setIsValid] = useState<boolean>(false);
const [item, setItem] = useState<string>('');
const [cardPrice, setCardPrice] = useState<string>('');
 function decodeData(base64: string) {
    // URL-barát base64 visszaalakítása normál base64 formátumra
    let base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
    // Base64 dekódolás
    let utf8Data = atob(base64String);
    // UTF-8 dekódolás
    return decodeURIComponent(escape(utf8Data));
  }
  useEffect(() => {
  }
  , []);
  const handleQRString = (e: string) => {
    var renderedData = e;
    if (e.includes("?")){
      renderedData = e.split("?")[1];
    }
    setQrString(renderedData);
    axios.get(`https://leltar.slimstore.hu/cardQr.php?qrstring=${renderedData}`)
        .then(response => {
          console.log(response);
          setIsValid(response.data[0]["isvalid"] === "1" ? true : false);
          if(response.data[0]["isvalid"] === "1") {
            setQrShow(false);
          } else {
            window.alert("Érvénytelen kártya!")
          }
          const data = decodeData(response.data[0]["qrstring"])
          const items = JSON.parse(data);
          setItem(items.cardType)
          setCardPrice(items.price)

        })
        .catch(error => {
          console.error('Hiba a QR kód olvasásakor:', error);
        });

  }
  const handlePatch = (e: string) => {
    if(window.confirm("Biztosan érvényesíted a kártyát?")) {
        axios.patch('https://leltar.slimstore.hu/cardQr.php?userid='+UserManager.getInstance().getUserId()+'&qrstring='+qrString).then(response => {
            window.alert('Kártya érvényesítve',);
            window.location.reload();
          });
    }
    
  };
  return (
    <div>
       {qrShow && (<div><Scanner onScan={(result) => handleQRString(result[0].rawValue)} /></div>)}
       {isValid && (<div>
         <h1>Érvényes {item} - {cardPrice} Ft</h1>
            <button className='adminButton' onClick={() => handlePatch(qrString)}>Kártya érvényesítése</button>
            </div>)}
  </div>
  );
}

export default QR;