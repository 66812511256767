import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { House } from '@mui/icons-material';
import { NetworkManager } from '../services/NetworkManager';

interface Location {
  id: number;
  name: string;
}

const LocationsList = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [newLocation, setNewLocations] = useState('');
  const networkManager = new NetworkManager();
  
  useEffect(() => {
    fetchLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchLocations() {
    try {
      const response = await networkManager.get('/api/locations/');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching part types:', error);
    }
  }
  // Handle new part type addition
  const handleAddPartType = async () => {
    if (!newLocation) return;

    try {
      const response = await networkManager.post('/api/locations/', {
        name: newLocation,
      });
      setLocations((prev) => [...prev, response.data]);
      setNewLocations(''); // Clear input field after adding
    } catch (error) {
      console.error('Error adding new part type:', error);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: '600px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Helyszínek
      </Typography>

      {/* Input field for adding new part types */}
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          fullWidth
          label="Új helyszín"
          variant="outlined"
          value={newLocation}
          onChange={(e) => setNewLocations(e.target.value)}
        />
        <IconButton color="primary" onClick={handleAddPartType} sx={{ ml: 1 }}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Divider for visual separation */}
      <Divider sx={{ mb: 2 }} />

      {/* List of part types */}
      <List>
        {locations.map((location) => (
          <ListItem key={location.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <ListItemIcon>
              <House />
            </ListItemIcon>
            <ListItemText primary={location.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default LocationsList;