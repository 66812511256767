import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Autocomplete,
  useTheme,
} from "@mui/material";
import { Remove } from "@mui/icons-material";
import { NetworkManager } from "../services/NetworkManager";
import { Income, ServiceSheet, Worksheet } from "../models/WorkSheetModel";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { Scanner } from '@yudiel/react-qr-scanner';
import { UserManager } from "../services/UserManager";


interface Location {
  id: number;
  name: string;
}

interface ServiceSheetFormProps {
  is_general?: boolean; // Opcionális boolean paraméter
}

const ServiceSheetForm: React.FC<ServiceSheetFormProps> = ({is_general=false}) => {
  const [serviceSheets, setServiceSheets] = useState<Worksheet[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [newLocation, setNewLocations] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const networkManager = useMemo(() => new NetworkManager(), []);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [isOpened, setIsOpened] = useState(false);
  const theme = useTheme();
  const [filteredSheets, setFilteredSheets] = useState<Worksheet[]>([]); // Szűrt adatok
  const [globalIncome, setGlobalIncome] = useState<number>(0);
  const [formData, setFormData] = useState<ServiceSheet>({
    payment_method: "card",
    expenses: [],
    incomes: [],
    location: "",
    is_general: is_general ,
  });
  
  useEffect(() => {

      // A formData alapértelmezett `is_general` értékének frissítése
  setFormData((prevData) => ({
    ...prevData,
    is_general: is_general,
  }))

  }, [is_general]);


  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
  };

  const fetchLocations = useCallback(async () => {
    try {
      const response = await networkManager.get('/api/locations/');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, [networkManager]);

  const getWorksheets = useCallback(async () => {
    setIsLoading(true);
    
    const date = selectedDate?.format('YYYY-MM-DD') || dayjs();
    const response = await networkManager.get('/api/worksheets/?date=' + date);
    const newServiceSheets = response.data.worksheets;
    // Az új elemeket hozzáadjuk a meglévőkhöz
    setServiceSheets(newServiceSheets);
    setIsLoading(false);
  }, [networkManager, selectedDate]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: "expense" | "income"
  ) => {
    const { name, value } = e.target;
  //  const cleanedValue = value.replace(/\s/g, ''); // Eltávolítja a szóközöket
 
    if (type === "expense") {
      const expenses = [...formData.expenses];
      expenses[index] = { ...expenses[index], [name]: value };
      setFormData({ ...formData, expenses });
    } else {
      const incomes = [...formData.incomes];
      incomes[index] = { ...incomes[index], [name]: value };
      setFormData({ ...formData, incomes });
    }
  };

  useEffect(() => {
    setShowForm(false);
    getWorksheets();
    fetchLocations();
  },[selectedDate, fetchLocations, getWorksheets]);

  useEffect(() => {
    let totalIncome = 0;
    filteredSheets.forEach(sheet => {
      totalIncome += calculateIncome(sheet.user, sheet.total_incomes, sheet.total_expenses, sheet.incomes,sheet.payment_method);
    });
    setGlobalIncome(totalIncome);
  }, [filteredSheets]);
  

  const addExpense = () => {
    setFormData({
      ...formData,
      expenses: [...formData.expenses, { description: "", price: "0", part: null, order_related: false }],
    });
  };
  const openQRScanner = () => {
    setIsOpened(true);
  }

  const handleScan = (result: string | null) => {
    if (result) {
      addPart(result);
      setIsOpened(false);
    }
  };
  const addPart = async (partId: string) => {
    const parsedPartId = JSON.parse(partId);
    await networkManager.get(`/api/parts/` + parsedPartId).then((response) => {
      const part = response.data;
      setFormData({
        ...formData,
        expenses: [...formData.expenses, { description: part.phone_type_name + " - " + part.part_type_name, price: part.price, part: part.part_id, order_related: true }],
      });
    }).catch((error) => {
    })
  
  }
  const removeExpense = (index: number) => {
    const expenses = formData.expenses.filter((_, i) => i !== index);
    setFormData({ ...formData, expenses });
  };

  const calculateIncome = (userid: number, bevetel: number, kiadas: number, incomes: Income[],paymentmethod:string) => {
    const loggeduserId = parseInt(UserManager.getInstance().getUserId());

    let hasDiscount = incomes.some((row) => {
      const amount = parseInt(row.amount);
      const newPrice = row.new_price ? parseInt(row.new_price) : amount; // Ha new_price undefined, az amount értékét használja
      return amount !== newPrice;
    });
    
   
    const techinicianComission = incomes.reduce((sum, row) => {
      const nettIncome = Math.round(parseInt(row.amount) / 1.27);
      return sum + (nettIncome > 0 ? nettIncome : 0);
    }, 0);
    if(loggeduserId===26){
      if(paymentmethod!=="warranty"){
        if(hasDiscount){
          return Math.round(((bevetel - kiadas)/1.27)-(techinicianComission-kiadas/1.27) * 0.1)
        } else{
          return Math.round(((bevetel - kiadas)/1.27) * 0.90)
        }
      }else{
        return Math.round((bevetel - kiadas)/1.27)
      }
 
    } else{

      if ((userid === 23)&&(loggeduserId===23)) { // Ellenőrzés, hogy csak a megfelelő ID esetén fusson le
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas)/1.27) * 0.1)
        } else {
          return 0;
        }
      }else if((userid !== 23)&&(loggeduserId===23)){
        if (bevetel - kiadas > 0) {
          return Math.round(((bevetel - kiadas)/1.27) * 0.05)
        } else {
          return 0;
        }
      }else if(loggeduserId===userid){
        if (bevetel - kiadas > 0) {
          return Math.round(((bevetel - kiadas)/1.27) * 0.05)
        } else {
          return 0
        }
          
      }else{
        return 0
      }
  };
  };
  const addIncome = () => {
    setFormData({
      ...formData,
      incomes: [...formData.incomes, { description: "", amount: "0", card: ""}],
    });
  };

  const removeIncome = (index: number) => {
    const incomes = formData.incomes.filter((_, i) => i !== index);
    setFormData({ ...formData, incomes });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Általános ellenőrzés
    if (!is_general && formData.expenses.length === 0 && formData.incomes.length === 0) {
      alert("Legalább egy bevételt és egy kiadást meg kell adni!");
      return;
    }
    var payment_method = formData.payment_method;
    var expenses = formData.expenses;
    var incomes = formData.incomes;
    var location = formData.location;
    if (!window.confirm("Biztosan rögzíted a munkalapot ezekkel az adatokkal: \n Fizetési mód:" + payment_method + "\n Kiadások: " + expenses.map((expense) => expense.description + " - " + expense.price.toLocaleString() + " Ft").join(", ") + "\n Bevételek: " + incomes.map((income) => income.description + " - " + income.amount.toLocaleString() + " Ft").join(", ") + "\n Helyszín: " + location)) {
      return;
    }
    if (!is_general) {
      if (formData.expenses.length === 0) {
        alert("Legalább egy kiadást meg kell adni!");
        return;
      } else {
        // Üres description vagy amount ellenőrzése
        const invalidExpense = formData.expenses.find(
          (item) => item.description === "" || item.price === "0" 
        );
  
        if (invalidExpense) {
          alert(
            "Minden kiadásnál meg kell adni egy érvényes leírást és pozitív összeget!"
          );
          return;
        }
      }


      if(formData.incomes.length === 0 && formData.payment_method!=="warranty"){
        alert("Legalább egy bevételt meg kell adni!");
        return;
      }else{
        const invalidIncomes = formData.incomes.find(
          (item) => item.description === "" || item.amount === "0" 
        );
  
        if (invalidIncomes && formData.payment_method!=="warranty") {
          alert(
            "Minden bevételnél meg kell adni egy érvényes leírást és pozitív összeget!"
          );
          return;
        }

      }
    }else{

      if (formData.expenses.length === 0) {
        alert("Legalább egy kiadást meg kell adni!");
        return;
      } else {
        // Üres description vagy amount ellenőrzése
        const invalidExpense = formData.expenses.find(
          (item) => item.description === "" || item.price === "0" 
        );
  
        if (invalidExpense) {
          alert(
            "Minden kiadásnál meg kell adni egy érvényes leírást és pozitív összeget!"
          );
          return;
        }
      }
    }
    await networkManager.post('/api/worksheets/', formData).then(() => {
      getWorksheets();
      setFormData({
        payment_method: "card",
        expenses: [],
        incomes: [],
        location: newLocation,
        is_general: false,
      });
    }).catch((error) => {
      console.error('Worksheet creation error:', error);
    }); 
  
    setShowForm(false);
  };
  

  function ownIncomeByRow(userid: number, bevetel: number, kiadas: number,incomes : Income[],paymentmethod:string): string  {
    const loggeduserId = parseInt(UserManager.getInstance().getUserId()); // Local storage id konvertálása számra

    let hasDiscount = incomes.some((row) => {
      const amount = parseInt(row.amount);
      const newPrice = row.new_price ? parseInt(row.new_price) : amount; // Ha new_price undefined, az amount értékét használja
      return amount !== newPrice;
    });
    
    const techinicianComission = incomes.reduce((sum, row) => {
      const nettIncome = Math.round(((parseInt(row.amount)) / 1.27));
      return sum + (nettIncome > 0 ? nettIncome : 0); // Csak pozitív nettó nyereséget számolunk
    }, 0);

    if(loggeduserId===26){
      if(paymentmethod!=='warranty'){
        if(hasDiscount){
          return Math.round(((bevetel - kiadas)/1.27)-(techinicianComission-kiadas/1.27) * 0.1).toLocaleString() + " Ft"; 
        } else{
          return Math.round(((bevetel - kiadas)/1.27) * 0.90).toLocaleString() + " Ft"; 
        }
      }else{
        return Math.round((bevetel - kiadas)/1.27).toLocaleString()+"Ft";
      }
   
    } else{

      if ((userid === 23)&&(loggeduserId===23)) { // Ellenőrzés, hogy csak a megfelelő ID esetén fusson le
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.1).toLocaleString() + " Ft"; // 10%-os saját nyereség
        } else {
          return "n.a."; // Ha negatív a nyereség
        }
      }else if((userid !== 23)&&(loggeduserId===23)){
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission - kiadas/1.27)) * 0.05).toLocaleString() + " Ft"; // 5%-os saját nyereség
        } else {
          return "n.a."; // Ha negatív a nyereség
        }
      }else if(loggeduserId===userid){
        if (bevetel - kiadas > 0) {
          return Math.round(((techinicianComission-kiadas/1.27)) * 0.05).toLocaleString() + " Ft"; // 5%-os saját nyereség
        } else {
          return "n.a."; // Ha negatív a nyereség
        }
          
      }else{
        return "n.a."; 
      }
   
    }
    }
    


  useEffect(() => {

   if(is_general){
     setFilteredSheets(serviceSheets.filter(sheet => sheet.is_general===true));

   }else{

     setFilteredSheets(serviceSheets.filter(sheet => sheet.is_general===false));
   }
  
  

  }, [serviceSheets, is_general]);


  function companyProfit(filteredSheets: Worksheet[]): string {
    const totalIncome = filteredSheets.reduce((sum, row) => {
      const nettIncome = Math.round(((row.total_incomes - row.total_expenses) / 1.27));
      return sum + nettIncome // Csak pozitív nettó nyereséget számolunk
    }, 0);
    return totalIncome.toLocaleString();
  }
  //A kiziettt jutalékok oszlophoz kell

  function calculatePaidCommission(userid: number,username:string, bevetel: number, kiadas: number,incomes : Income[]) {
   
const techinicianComission = incomes.reduce((sum, row) => {
  const nettIncome = Math.round(((parseInt(row.amount)) / 1.27));
  return sum + (nettIncome > 0 ? nettIncome : 0); // Csak pozitív nettó nyereséget számolunk
}, 0);
if((techinicianComission-kiadas/1.27)>0){
  if (userid===23) {
    return username +"("+Math.round((techinicianComission-kiadas/1.27) * 0.1).toLocaleString() + " Ft)"; // 10%-os saját nyereség
  } else {
    return `${username} (${Math.round((techinicianComission - kiadas / 1.27) * 0.05).toLocaleString()} Ft) / Máté (${Math.round((techinicianComission - kiadas / 1.27) * 0.05).toLocaleString()} Ft)`;  // 5%-os saját nyereség
  }
}else{
  return "nincs jutalék"
}
  

  }
  function totalPaidCommission(filteredSheets: Worksheet[]): string {
    // Külső reduce a filteredSheets elemein
    const totalCommission = filteredSheets.reduce((sum, row) => {
      if (!row.incomes || row.incomes.length === 0) {
        return sum; // Ha nincs incomes, lépj tovább
      }
  
      // Belső reduce az incomes tömb feldolgozására
      const partCommission = row.incomes.reduce((partSum, partRow) => {
        // Nettó érték számítása
        const nettIncome = Math.round(
          ((parseInt(partRow.amount) - row.total_expenses) / 1.27) * 0.10
        );
  
        // Csak pozitív nettó értéket adj hozzá
        return partSum + (nettIncome > 0 ? nettIncome : 0);
      }, 0);
  
      // Az összeghez hozzáadjuk az aktuális row értékét
      return sum + partCommission;
    }, 0);
  
    // Formázott összeg visszatérítése
    return totalCommission.toLocaleString() + " Ft";
  }
  
  
  
  return (
    isLoading ? (
      <div>Töltés...</div>
    ) : (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Válassz dátumot"
        value={selectedDate}
        onChange={handleDateChange}
        slots={{
          textField: textFieldProps => <TextField {...textFieldProps} />
        }}
      />
      </LocalizationProvider>
      <br></br>
      <br></br>
      {selectedDate?.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && (<Button variant="contained" color="primary" onClick={() => setShowForm(true)}>
     {is_general ? ("Új kiadás felvitel"):("Új pénzmozgás felvitel")} 
      </Button>)}
      

      {showForm && (
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h5" gutterBottom>
              {is_general ? ("Új kiadás felvitel"):("Új pénzmozgás felvitel")} 
              </Typography>
              <form  onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={locations.map((location) => location.name)}
                    value={newLocation}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, location: newValue ?? '' });
                      setNewLocations(newValue ?? '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Helyszín"
                        style={{ width: "100%" }}
                        required
                      />
                    )}
                    freeSolo={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    value={formData.payment_method}
                    onChange={(e) =>
                      setFormData({ ...formData, payment_method: e.target.value as string })
                    }
                  >
                    <MenuItem value="card">Bankkártya</MenuItem>
                    <MenuItem value="cash">Készpénz</MenuItem>
                    <MenuItem value="warranty">Garanciális</MenuItem>
                  </Select>
                </Grid>
              </Grid>
                <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "8px" }}>Kiadások</Typography>
                
                {formData.expenses.map((expense, index) => (
                  <Grid container spacing={2} key={index} style={{ marginBottom: "8px" }}>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        name="description"
                        required={is_general}
                        fullWidth
                        value={expense.description}
                        onChange={(e) => handleInputChange(e, index, "expense")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                    <TextField
                      label="Ár"
                      name="price"
                      type="text" // Itt a type legyen "text", hogy ne akadjon össze a formázással
                      fullWidth
                      value={expense.price} // Formázás ezres csoportosítással
                      onChange={(e) => handleInputChange(e, index, "expense")}
                    />
                    </Grid>
                    <IconButton onClick={() => removeExpense(index)}>
                      <Remove />
                    </IconButton>
                  </Grid>
                ))}
                 <Button variant="outlined" onClick={addExpense}>
                  Kiadás hozzáadása
                </Button>
             { !is_general &&   <Button variant="outlined" onClick={openQRScanner} style={{ marginLeft: "8px" }}>
                  Alkatrész hozzáadása
                </Button>}
              
                {isOpened && (
                <Scanner
        onScan={(result) => handleScan(result[0].rawValue)}
        onError={(error) => console.error("QR Code error:", error)}
      /> )}
           <br></br>
       
           {!is_general &&     <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "8px" }}>Bevételek</Typography>}
                {formData.incomes.map((income, index) => (
                  <Grid container spacing={2} key={index} style={{ marginBottom: "8px" }}>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        name="description"
                        fullWidth
                        type="text"
                        value={income.description}
                        onChange={(e) => handleInputChange(e, index, "income")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                    <TextField
                      label="Ár"
                      name="amount"
                      type="text" // Itt a type legyen "text", hogy ne akadjon össze a formázással
                      fullWidth
                      value={income.amount} // Formázás ezres csoportosítással
                      onChange={(e) => handleInputChange(e, index, "income")}
                    />
                    </Grid>
                    <Grid item xs={2}>
                    <Select
                      value={formData.incomes[index]?.card || ""}
                      displayEmpty // Megjeleníti az üres értéket is
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const incomes = [...formData.incomes];
                        incomes[index] = { ...incomes[index], card: value };
                        setFormData({ ...formData, incomes });
                      }}
                    >
                      <MenuItem value="">Kártya nélkül</MenuItem> {/* Üres string mint alapértelmezett érték */}
                      <MenuItem value="garancia100">Garancia100</MenuItem>
                      <MenuItem value="garancia50">Garancia50</MenuItem>
                      <MenuItem value="silver">Ezüst</MenuItem>
                      <MenuItem value="gold">Arany</MenuItem>
                      <MenuItem value="gold14000"> Arany (14000Ft)</MenuItem>
                      <MenuItem value="silver7000">Ezüst (7000)</MenuItem>
                    </Select>
                    </Grid>
                    <IconButton onClick={() => removeIncome(index)}>
                      <Remove />
                    </IconButton>
                    
                  </Grid>
                ))}
              {!is_general &&  <Button variant="outlined" onClick={addIncome}>
                  Bevétel hozzáadása
                </Button>}
           
                <br></br>
                <Button variant="contained" color="primary" type="submit"   style={{ marginTop: "15px" }}>
                  Felvitel
                </Button>


  
              </form>
            </Paper>
          </Grid>
        </Grid>
      )}

  

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table key={"table"}>
          <TableHead key={"TablehxRow"}>
            <TableRow key={"Tablehead"}>
              <TableCell>ID</TableCell>
              <TableCell>Felhasználó</TableCell>
              <TableCell>Dátum</TableCell>
              <TableCell>Készpénz / Bankkártya</TableCell>
              <TableCell>Kiadások</TableCell>
              <TableCell>Bevételek</TableCell>
              <TableCell>Helyszín</TableCell>
           { !is_general &&  <TableCell sx={{ textAlign:"center", alignContent:"center" }}>Nettó nyereség</TableCell>}
            {!is_general &&  <TableCell sx={{ textAlign:"center" }}>  {parseInt(UserManager.getInstance().getUserId()) === 26 ? "Cégnyereség" : "Saját nyereség"}</TableCell>}
            {!is_general && parseInt(UserManager.getInstance().getUserId()) === 26 && (
  <TableCell sx={{ textAlign: "center" }}>Kiosztott jutalék</TableCell>
)}
            </TableRow>
          </TableHead>
          <TableBody> {}
            {filteredSheets.map((sheet) => (
              <TableRow key={sheet.id}>
                <TableCell>{sheet.id}</TableCell>
                <TableCell>{sheet.user}</TableCell>
                <TableCell>{sheet.id}</TableCell>
                <TableCell>{sheet.payment_method}</TableCell>
                <TableCell>
                {sheet.expenses && sheet.expenses.length > 0 && (
                  sheet.expenses.map((expense, i) => (
                    <div key={i}>
                      <Typography variant="subtitle2" color="textPrimary" component="span">
                        {expense.description} - {parseInt(expense.price).toLocaleString() + " Ft"}
                      </Typography>
                      <Typography variant="caption" color="textSecondary" component="span" style={{ marginLeft: 4 }}>
                        {"(nettó: " + Math.round(parseInt(expense.price) / 1.27).toLocaleString() + " Ft)"}
                      </Typography>
                    </div>
                  ))
                )}
              </TableCell>
                <TableCell>
                  {sheet.incomes && sheet.incomes.map((income, i) => (
                    <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px 12px",
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRadius: "8px",
                      margin: "8px 0",
                      backgroundColor: theme.palette.background.paper,
                      transition: "all 0.3s",
                      "&:hover": {
                        boxShadow: `0px 4px 12px ${theme.palette.grey[300]}`,
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    {/* Bal oldali, eredeti ár */}
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {income.description} - {parseInt(income.amount).toLocaleString()} Ft
                        <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 4 }}>
                          {"(nettó: " + Math.round(parseInt(income.amount) / 1.27).toLocaleString() + " Ft)"}
                        </Typography>
                      </Typography>
                      </Grid>
          
                      {/* Középső, kártya információ */}
                      <Grid item xs={3}>
                        <Typography variant="body2" color="textSecondary">
                          {income.card === "" ? "Kártya nélkül" : income.card}
                        </Typography>
                      </Grid>
          
                      {/* Jobb oldali, új ár */}
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" color="primary" fontWeight="600">
                          {income.new_price ? parseInt(income.new_price).toLocaleString() + " Ft" : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  ))}
                </TableCell>
                <TableCell>
                    <div key={sheet.location}>
                      {sheet.location}
                    </div>
                </TableCell>
             {!is_general &&   <TableCell align="center">
                <div key={sheet.location}>

                    {Math.round((sheet.total_incomes - sheet.total_expenses) / 1.27).toLocaleString()} Ft
                    
                </div>
              </TableCell>}
           {!is_general &&   <TableCell align="center">

              {ownIncomeByRow(sheet.user,sheet.total_incomes,sheet.total_expenses,sheet.incomes,sheet.payment_method)}
              </TableCell> }


            {!is_general  && <TableCell align="center">
              {calculatePaidCommission(sheet.user,sheet.user_name,sheet.total_incomes,sheet.total_expenses,sheet.incomes)}
              </TableCell> }

              </TableRow>
            ))}

        

{!is_general && <TableRow sx={{ border: "2px solid black", marginTop: "20px" }}>
          <TableCell colSpan={10} sx={{ textAlign: "right", paddingRight: "16px", border: "2px solid black" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px" }}>
              <Typography variant="body1">
              Nettó nyereség összesen: {companyProfit(filteredSheets)} Ft
              </Typography>
              <Typography variant="body1">
                {parseInt(UserManager.getInstance().getUserId()) === 26 ? "Cégnyereség" : "Saját nyereség a szervízből"} {globalIncome} Ft
              </Typography>
              {parseInt(UserManager.getInstance().getUserId()) === 26 && (
                <Typography variant="body1">
                  Kiosztott jutalék összesen: {totalPaidCommission(filteredSheets)}
                </Typography>
              )}
            </Box>
          </TableCell>
        </TableRow>

            }

          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ));
};



export default ServiceSheetForm;