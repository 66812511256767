import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  IconButton,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { NetworkManager } from '../services/NetworkManager';
import { PhoneIphone } from '@mui/icons-material';

interface PartType {
  id: number;
  name: string;
}

const PhoneTypesList = () => {
  const [phoneTypes, setPhoneTypes] = useState<PartType[]>([]);
  const [newPhoneTypes, setNewPhoneTypes] = useState('');
  const [filters, setFilters] = useState<string[]>([]); // Stores the unique filters
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null); // Currently selected filter
  const networkManager = new NetworkManager();

  useEffect(() => {
    async function fetchPhoneTypes() {
      try {
        const response = await networkManager.get('/api/phonetypes/');
        const fetchedPhoneTypes = response.data;
  
        // Generate filters from the first word of each name
        const uniqueFilters = Array.from(
          new Set<string>(fetchedPhoneTypes.map((phoneType: PartType) => phoneType.name.split(' ')[0]))
        );
        setFilters(uniqueFilters);
        setPhoneTypes(fetchedPhoneTypes);
      } catch (error) {
        console.error('Error fetching part types:', error);
      }
    }
  
    fetchPhoneTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle new part type addition
  const handleAddPhoneType = async () => {
    if (!newPhoneTypes) return;

    const [firstWord] = newPhoneTypes.split(' ');

    try {
      const response = await networkManager.post('/api/phonetypes/', {
        name: newPhoneTypes,
      });
      setPhoneTypes((prev) => [...prev, response.data]);

      // Update filters if the new part type's first word is unique
      if (!filters.includes(firstWord)) {
        setFilters((prev) => [...prev, firstWord]);
      }

      setNewPhoneTypes(''); // Clear input field after adding
    } catch (error) {
      console.error('Error adding new part type:', error);
    }
  };

  // Filter the phone types based on the selected filter
  const filteredPhoneTypes = selectedFilter
    ? phoneTypes.filter((phoneType) => phoneType.name.startsWith(selectedFilter))
    : phoneTypes;

  return (
    <Box sx={{ p: 3, maxWidth: '600px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Telefontípusok
      </Typography>

      {/* Input field for adding new part types */}
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          fullWidth
          label="Új telefontípus"
          variant="outlined"
          value={newPhoneTypes}
          onChange={(e) => setNewPhoneTypes(e.target.value)}
        />
        <IconButton color="primary" onClick={handleAddPhoneType} sx={{ ml: 1 }}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Divider for visual separation */}
      <Divider sx={{ mb: 2 }} />

      {/* Filter buttons */}
      <Box sx={{ mb: 2, display: 'flex', gap: 1 }}>
        {filters.map((filter) => (
          <Button
            key={filter}
            variant={selectedFilter === filter ? 'contained' : 'outlined'}
            onClick={() => setSelectedFilter(filter === selectedFilter ? null : filter)}
          >
            {filter}
          </Button>
        ))}
      </Box>

      {/* List of filtered part types */}
      <List>
        {filteredPhoneTypes.map((phoneType) => (
          <ListItem key={phoneType.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <ListItemIcon>
              <PhoneIphone />
            </ListItemIcon>
            <ListItemText primary={phoneType.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PhoneTypesList;