import { NetworkManager } from '../NetworkManager';
import { jwtDecode } from 'jwt-decode';

const apiManager = new NetworkManager();

// Login függvény
export async function login(username: string, password: string) {
  try {
    const response = await apiManager.post('/api/token/', {
      username: username,
      password: password,
    });

    // Ellenőrizzük, hogy van-e accessToken és refreshToken a válaszban
    const { access, refresh } = response.data;

    if (access && refresh) {
      localStorage.setItem('isSuperuser', jwtDecode<{ is_superuser: string }>(access).is_superuser);
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem('userid',  jwtDecode<{ user_id: string }>(access).user_id);
    } else {
      console.error('Tokenek nem találhatóak a válaszban.');
    }

    return response;
  } catch (error) {
    console.error('Login hiba:', error);
    throw error;
  }
}